import $ from "jquery";
import {handleValidation, waitForNotElement} from "./form.js";

/**
 * @typedef {object} Window The window object.
 * @property {jQuery} zipcodeFormButton The dynamically generated search button for zipcode form.
 */

/**
 * @typedef {object} Window The window object.
 * @property {jQuery} zipcodeForm2Button The dynamically generated search button for zipcode form 2.
 */

/**
 * Posts a zipcode form to ad-listing page.
 *
 * @callback adListingFunction
 * @param {jQuery} zipcodeForm the zip code form
 */
const postZipcodeFormToAdListing = function (zipcodeForm) {
    if (!window.location.pathname.includes("mobile") && sessionStorage.getItem("deviceInfo") && sessionStorage.getItem("deviceInfo").includes("desktop")) {
        const newTab = window.open("/please-wait", "popover");
        if (newTab) {
            zipcodeForm.attr("target", "popover");
            window.location = "/ad-listing.php";
        }
    }
};

/**
 * Add validation to zip code form before submit.
 *
 * @param {jQuery} zipcodeForm the zip code form
 * @param {function} zipcodeFormCallbackFunction submits the zip form
 */
export function addZipcodeFormSubmitListener(zipcodeForm, zipcodeFormCallbackFunction) {
    let zipcodeFormButton = zipcodeForm.find("button[type=submit]");
    zipcodeFormButton.removeAttr("disabled");
    zipcodeForm.on("submit", function () {
        const zipcodeFormButton = zipcodeForm.find("[type=submit]");
        const zipcodeVerifying = "#" + zipcodeForm.attr("id") + " .zipcode.verifying";
        if ($(zipcodeVerifying).length === 0) {
            submitZipForm(zipcodeForm, zipcodeFormButton, zipcodeFormCallbackFunction);
        } else {
            waitForNotElement(zipcodeVerifying, () => {
                submitZipForm(zipcodeForm, zipcodeFormButton, zipcodeFormCallbackFunction);
            });
        }
        zipcodeFormButton.trigger("reset");

        return false;
    });
}

let zipcodeForms = $("form[data-type=zipcode-form]");
zipcodeForms.each(function (counter, zipcodeForm) {
    addZipcodeFormSubmitListener($(zipcodeForm), postZipcodeFormToAdListing);
});

/**
 * Submit zip form handler.
 *
 * @param {jQuery} zipcodeForm the zip code form
 * @param {jQuery} zipcodeFormButton the submit button on the form to disable
 * @param {function} zipcodeFormCallbackFunction submits the zip form
 */
export function submitZipForm(zipcodeForm, zipcodeFormButton, zipcodeFormCallbackFunction) {
    if (zipcodeForm.find(".is-invalid").length === 0 && handleValidation(zipcodeForm.find("[name=zipcode]"), zipcodeForm.find(".zipcode-error"))) {
        setTimeout(zipcodeFormCallbackFunction(zipcodeForm), 200);
        zipcodeForm.off("submit");
        zipcodeForm.trigger("submit");
    } else {
        zipcodeForm.find(".is-invalid").first().focus();
        zipcodeFormButton.removeAttr("disabled");
        zipcodeFormButton.trigger("reset");
    }
}
